import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/zap-onboard-api/zap-onboard-api/services-unmanaged/zap-onboard-home-page/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Qr1 } from '../../../components/images/support/users/Qr1.generated';
import { Qr2 } from '../../../components/images/support/users/Qr2.generated';
import { Qr3 } from '../../../components/images/support/users/Qr3.generated';
import { Qr4 } from '../../../components/images/support/users/Qr4.generated';
import { Qr5 } from '../../../components/images/support/users/Qr5.generated';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "invite-a-member-by-qr-code-"
    }}>{`Invite a Member by QR Code 🤳`}</h1>
    <p>{`The previous sections show how you can invite a user into Canyou.`}</p>
    <p>{`Canyou also has the ability to allow users to join a business by scanning a QR Code.`}</p>
    <p>{`This means that a user has the ability to start a workflow without the need for an Admin or Supervisor to invite them.`}</p>
    <p>{`This works great for:`}</p>
    <ul>
      <li parentName="ul">{`Employee onboarding`}</li>
      <li parentName="ul">{`Subcontractor onboarding`}</li>
      <li parentName="ul">{`Site inductions`}</li>
    </ul>
    <p>{`This section shows how to setup the QR code and allow users to join the business.`}</p>
    <hr></hr>
    <ol>
      <li parentName="ol">{`Navigate to the users menu and click `}<strong parentName="li">{`Settings`}</strong>{`.`}</li>
    </ol>

    <Qr1 mdxType="Qr1" />
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`There are 2 options you can choose at this point for setup:`}</li>
    </ol>
    <p><strong parentName="p">{`None - Require approval`}</strong></p>
    <p>{`This option means that every time a new user scans the QR code a request will be sent to all `}<strong parentName="p">{`Admins`}</strong>{` in the business requesting to grant the user access.
A user will not be able to start any workflows until one of the `}<strong parentName="p">{`Admins`}</strong>{` approves access.`}</p>
    <p><strong parentName="p">{`Position`}</strong></p>
    <p>{`You can select from your existing list of positions and set it as the `}<strong parentName="p">{`Default`}</strong>{` position for when a new user scans the QR code. This means that
no approval is required from an `}<strong parentName="p">{`Admin`}</strong>{` and the employee can start their workflows immediately. If you do not have any positions set up
`}<a parentName="p" {...{
        "href": "/support/positions/creating-a-position"
      }}>{`click here`}</a>{` to learn more.`}</p>

    <Qr2 mdxType="Qr2" />
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Setting up a QR code with a position selected is most common so in this example "Chef" is selected. Click `}<strong parentName="li">{`Save`}</strong></li>
    </ol>

    <Qr3 mdxType="Qr3" />
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Click `}<strong parentName="li">{`Download`}</strong></li>
    </ol>

    <Qr4 mdxType="Qr4" />
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`Now you can print out your QR Code. Place it in an area that users can easily access. We also recommend you laminate it as well. This can now be scanned by
a user from their own device. Scanning the QR code will redirect the user to the Canyou app where they will trigger the process to join the business and start their
workflows.`}</li>
    </ol>

    <Qr5 mdxType="Qr5" />
    <h1 {...{
      "id": "great-work-you-have-now-set-up-your-qr-code-"
    }}>{`Great work you have now set up your QR Code 🥳`}</h1>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      